<template>
  <div class="WorkticketCheckGasAdd has-nav-bar">
    <WorkticketHeader
      title="气体检测"
      :show-gas-record="true"
      @clickGasRecord="clickGasRecord"
    />

    <LoadView v-model="loadStatus">
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <van-field
          :value="form._placeName"
          readonly
          is-link
          name="place"
          label="位置信息"
          input-align="right"
          placeholder="请选择"
          @click="placeClick"
        />
        <van-field
          v-model="form.location"
          name="value0"
          label="具体检测位置"
          placeholder="请输入"
          input-align="right"
          error-message-align="right"
          maxlength="100"
          :rules="[
            { validator, message: '请输入具体检测位置', trigger: 'onBlur' }
          ]"
        />
        <GasTablePlus
          v-if="isBy && isCustomType"
          :title="customCheck['1'].title"
          :gas-item-data="gasItemData"
          :gas-ids.sync="customCheck['1'].gasIds"
          :selected-items.sync="customCheck['1'].monitorItemList"
          :sample-time.sync="customCheck['1'].sampleTime"
          :check-time.sync="customCheck['1'].checkTime"
          :result.sync="customCheck['1']._result"
        />
        <GasTablePlus
          v-if="isBy && isCustomType"
          :title="customCheck['2'].title"
          :gas-item-data="gasItemData"
          :gas-ids.sync="customCheck['2'].gasIds"
          :selected-items.sync="customCheck['2'].monitorItemList"
          :sample-time.sync="customCheck['2'].sampleTime"
          :check-time.sync="customCheck['2'].checkTime"
          :result.sync="customCheck['2']._result"
        />
        <GasTablePlus
          v-if="isBy && isCustomType"
          :title="customCheck['3'].title"
          :gas-item-data="gasItemData"
          :gas-ids.sync="customCheck['3'].gasIds"
          :selected-items.sync="customCheck['3'].monitorItemList"
          :sample-time.sync="customCheck['3'].sampleTime"
          :check-time.sync="customCheck['3'].checkTime"
          :result.sync="customCheck['3']._result"
        />
        <FieldDateTimePicker
          v-if="isBy && !isCustomType"
          label="取样时间"
          :value.sync="form.sampleTime"
          :rules="[
            {
              required: true,
              message: '请选择取样时间',
              trigger: 'onChange'
            }
          ]"
        />
        <GasTable
          v-if="!isBy || (isBy && !isCustomType)"
          :gas-ids.sync="gasIds"
          :gas-item-data="gasItemData"
          :selected-items.sync="form.monitorItemList"
          :force-gas-type-list="workTypeInfo.forceGasTypeList"
          :result.sync="form.result"
        />
        <van-field name="value2" label="检测结果" input-align="right">
          <template #input>
            <van-radio-group
              v-model="form.result"
              direction="horizontal"
              :disabled="true"
            >
              <van-radio :name="1">合格</van-radio>
              <van-radio :name="0">不合格</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <FieldDateTimePicker
          v-if="isBy && !isCustomType"
          label="检测时间"
          :value.sync="form.checkTime"
          :rules="[
            {
              required: true,
              message: '请选择检测时间',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          name="value3"
          :label="TEXT_WORK_ANALYST"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              validator: analysisPersonValidator,
              message: '请完成签名',
              trigger: 'onBlur'
            }
          ]"
        >
          <template #input>
            <Tag
              :type="form.personInfo.fileId ? 'green' : 'white'"
              :tick="!!form.personInfo.fileId"
              @click.native="handleClickTag(form.personInfo.fileId)"
            >
              {{ form.personInfo.personName }}
            </Tag>
          </template>
        </van-field>
      </van-form>
      <BottomFixedBox>
        <van-button class="btn-primary" @click="onSubmit">提交</van-button>
      </BottomFixedBox>
    </LoadView>
    <select-popup
      :choose-value="form.place"
      :data="placeOptions"
      title="位置信息"
      :visible="placePopupVisible"
      show-filter
      :popup-style="{
        height: '50vh'
      }"
      @close="placePopupVisible = false"
      @confirm="placePopupConfirm"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import WorkticketHeader from "@/components/header/WorkticketHeader";
import { parseTime } from "@/utils";
import {
  addWorkGasMonitor,
  getWorkGasMonitorItems,
  getGasMonitorPlace,
  getWorkTypeInfo
} from "@/api/workticket";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import LoadView from "@/components/loadview/LoadView";
import { LOAD_STATUS } from "@/components/loadview/utils";
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import { deepClone } from "@/utils/index";
import FieldDateTimePicker from "./components/FieldDateTimePicker";
import GasTable from "./components/GasTable";
import GasTablePlus from "./components/GasTablePlus";

export default {
  name: "WorkticketCheckGasAdd",
  components: {
    LoadView,
    BottomFixedBox,
    WorkticketHeader,
    Tag,
    FieldDateTimePicker,
    GasTable,
    GasTablePlus
  },
  data() {
    return {
      specialId: this.$route.query.specialId || "",
      workTypeId: this.$route.query.workTypeId || "",
      ticketId: this.$route.query.ticketId || "",
      loadStatus: LOAD_STATUS.LOADING,
      LOAD_STATUS,
      testItem: [],
      form: {
        place: "",
        _placeName: "",
        location: "",
        monitorItemList: [],
        result: 1,
        // NOTE: 只有检测人才能添加，检测人就是当前登陆人。
        personInfo: {
          ticketId: "",
          personType: "5",
          personId: "",
          personName: "",
          fileId: "",
          type: ""
        },
        specialTicketId: this.$route.query.specialId || "", // 小票id
        ticketId: this.$route.query.ticketId || "", // 大票id
        // validTime: "",
        sampleTime: "",
        checkTime: "",
        monitorSampleList: []
      },
      fieldPropsInput: {
        placeholder: "请输入",
        clearable: true,
        "input-align": "center",
        "error-message-align": "center"
      },
      gasItemData: [],
      gasIds: [],
      placeOptions: [],
      placePopupVisible: false,
      workTypeInfo: {},
      customCheck: {
        "1": {
          title: "取样位置：上（左）",
          sampleLocation: 1,
          monitorItemList: [],
          sampleTime: "",
          checkTime: "",
          gasIds: [],
          _result: 1
        },
        "2": {
          title: "取样位置：中",
          sampleLocation: 2,
          monitorItemList: [],
          sampleTime: "",
          checkTime: "",
          gasIds: [],
          _result: 1
        },
        "3": {
          title: "取样位置：下（右）",
          sampleLocation: 3,
          monitorItemList: [],
          sampleTime: "",
          checkTime: "",
          gasIds: [],
          _result: 1
        }
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    isCustomType() {
      const specialJobType = Number(this.workTypeInfo.specialJobType);
      // 2: 受限空间，17: 清筒型库作业
      return [2, 17].includes(specialJobType);
    },
    customResult() {
      return (
        this.customCheck["1"]._result &&
        this.customCheck["2"]._result &&
        this.customCheck["3"]._result
      );
    }
  },
  created: async function() {
    const { ticketId, specialId, workTypeId } = this.$route.query;
    this.placeOptions = await getGasMonitorPlace();
    this.workTypeInfo = await getWorkTypeInfo({
      ticketId,
      specialId,
      workTypeId
    });
    const find = this.placeOptions.find(item => item.value === "1");
    this.form.place = find?.value || "";
    this.form._placeName = find?.label || "";
  },
  mounted: function() {
    this.loadItem();
  },
  methods: {
    handleClickTag(fileId) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      esign.show(imageRes => {
        this.form.personInfo.fileId = imageRes.id;
      });
    },
    loadItem() {
      //获取气体检测item
      const params = {
        workTypeId: this.workTypeId,
        specialWorkId: this.specialId,
        ticketId: this.ticketId
      };
      getWorkGasMonitorItems(params)
        .then(res => {
          const ids = res.gasSelectList || [];
          this.gasIds = ids;
          this.gasItemData = deepClone(res.gasMonitorDictVOS || []);
          const f = (res.gasMonitorDictVOS || []).filter(i =>
            ids.includes(i.id)
          );
          this.form.monitorItemList = f.map(i => {
            i.value2 = "";
            return i;
          });
          const info = res.workerVOS?.[0];
          this.form.personInfo = {
            ticketId: info.ticketId,
            personType: info.workerType,
            personId: info.workerId,
            personName: info.workerName,
            fileId: "",
            type: info.orgType
          };
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    validator(val) {
      return !!val;
    },
    analysisPersonValidator() {
      return !!this.form.personInfo.fileId;
    },
    onSubmit() {
      this.$refs.form.validate().then(() => {
        if (this.isBy && this.isCustomType) {
          if (
            !this.customCheck["1"].monitorItemList.length ||
            !this.customCheck["2"].monitorItemList.length ||
            !this.customCheck["3"].monitorItemList.length
          ) {
            return this.$toast.fail("请选择气体检测项");
          }
        } else {
          console.log(777, this.form.monitorItemList);
          if (!this.form.monitorItemList.length) {
            return this.$toast.fail("请选择气体检测项");
          }
        }
        this.checkOk();
      });
    },
    checkOk() {
      this.$dialog
        .confirm({
          message: "确认提交？"
        })
        .then(res => {
          this.form.signatureList = [this.form.personInfo];
          const params = deepClone(this.form);
          params.monitorItemList.forEach(i => {
            i.value = i.value2;
          });
          if (this.isBy && this.isCustomType) {
            params.monitorSampleList = [
              {
                ...this.customCheck["1"],
                monitorItemList: this.customCheck["1"].monitorItemList.map(
                  item => {
                    item.value = item.value2;
                    return item;
                  }
                )
              },
              {
                ...this.customCheck["2"],
                monitorItemList: this.customCheck["2"].monitorItemList.map(
                  item => {
                    item.value = item.value2;
                    return item;
                  }
                )
              },
              {
                ...this.customCheck["3"],
                monitorItemList: this.customCheck["3"].monitorItemList.map(
                  item => {
                    item.value = item.value2;
                    return item;
                  }
                )
              }
            ];
          }
          addWorkGasMonitor(params)
            .then(res => {
              if (res) {
                this.$router.go(-1);
                this.$toast.success("操作成功");
                return;
              }
              this.$toast.success("提交失败，请重试");
            })
            .catch(error => {
              this.$toast.fail("提交失败，请重试");
            });
        });
    },
    clickGasRecord() {
      this.$router.push("/workticket/checkgasRecord/" + this.specialId);
    },
    placeClick() {
      this.placePopupVisible = true;
    },
    placePopupConfirm({ label, value }) {
      this.form.place = value;
      this.form._placeName = label;
    }
  },
  watch: {
    customResult() {
      this.form.result = this.customResult;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketCheckGasAdd {
  .content-box {
    padding-top: 10px;
  }
}
</style>

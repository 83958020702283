var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-cell',{attrs:{"title":_vm.title,"border":false}}),_c('FieldDateTimePicker',{attrs:{"label":"取样时间","value":_vm.innerSampleTime,"rules":[
      {
        required: true,
        message: '请选择取样时间',
        trigger: 'onChange'
      }
    ],"editable":_vm.editable},on:{"update:value":function($event){_vm.innerSampleTime=$event}}}),_c('van-cell',{class:[_vm.editable ? 'gas-table-wrap' : '']},[_c('GasTable',{attrs:{"gas-ids":_vm.innerGasIds,"gas-item-data":_vm.gasItemData,"selected-items":_vm.innerSelectedItems,"result":_vm.innerResult,"editable":_vm.editable},on:{"update:gasIds":function($event){_vm.innerGasIds=$event},"update:gas-ids":function($event){_vm.innerGasIds=$event},"update:selectedItems":function($event){_vm.innerSelectedItems=$event},"update:selected-items":function($event){_vm.innerSelectedItems=$event},"update:result":function($event){_vm.innerResult=$event}}})],1),_c('FieldDateTimePicker',{attrs:{"label":"检测时间","value":_vm.innerCheckTime,"rules":[
      {
        required: true,
        message: '请选择检测时间',
        trigger: 'onChange'
      }
    ],"editable":_vm.editable},on:{"update:value":function($event){_vm.innerCheckTime=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="[!editable ? 'gas-table-wrap' : '']">
    <div v-if="editable" class="gas-row">
      <p class="check-tips">如现场不涉及某种检测项，检测值可为空。</p>
      <van-button class="btn-primary btn" size="mini" @click="gasItemChoose"
        >选择</van-button
      >
    </div>
    <van-field>
      <template #input>
        <table class="table-check-gas">
          <thead>
            <tr>
              <td>检测项</td>
              <td>合格范围</td>
              <td>检测值</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in innerSelectedItems" :key="index">
              <td>
                <p>
                  <van-icon
                    :class="`gasType gasType${item.gasType}`"
                    class-prefix="iconfont"
                    :name="gasTypeIconSwitch(item)"
                  />
                  {{ item.name }}
                </p>
              </td>
              <td>
                {{ `${item.rangeMin}-${item.rangeMax} ${item.unitName}` }}
              </td>
              <td>
                <!-- 检测值必须是数字，整数最大7位，小数位最多保留2位小数 -->
                <van-field
                  v-if="editable"
                  :key="`${index}检测值`"
                  v-model="item.value2"
                  v-bind="fieldPropsInput"
                  type="number"
                  :rules="[
                    {
                      validator: detection,
                      trigger: 'onBlur',
                      message: ' '
                    }
                  ]"
                  @input="detectionValueInput"
                />
                <span v-else-if="item.value">{{
                  `${item.value} ${item.unitName}`
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </van-field>
    <SelectPopupGrade
      v-if="editable"
      ref="selectPopupGrade"
      v-model="innerGasIds"
      :multi="true"
      :immediately="false"
      list-key="id"
      :props="{
        label: 'name',
        value: 'id'
      }"
      output-type="array"
      filter-key="name"
      title="气体检测设置"
      :list="gasItemData"
      :visible.sync="popupVisible"
      popup-height="70%"
      :is-show-confrim-icon="false"
      is-show-footer
      :submit-validate="submitValidate"
      @sure="gasItemPopupChange"
    >
      <template slot="content" slot-scope="{ row }">
        {{ row.name }}（{{ row.gasTypeName }}）
      </template>
    </SelectPopupGrade>
  </div>
</template>

<script>
export default {
  name: "GasTable",
  props: {
    value: {
      type: String,
      default: ""
    },
    gasIds: {
      type: Array,
      default: () => []
    },
    gasItemData: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    forceGasTypeList: {
      type: Array,
      default: () => []
    },
    result: {
      type: Number,
      default: 1
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fieldPropsInput: {
        placeholder: "请输入",
        clearable: true,
        "input-align": "center",
        "error-message-align": "center"
      },
      forceGasTypeOptions: {
        "1001": "可燃气体",
        "2001": "有毒有害气体",
        "3001": "氧气含量"
      },
      innerGasIds: [],
      innerSelectedItems: [],
      popupVisible: false,
      submitValidate: true
    };
  },
  mounted() {
    this.innerSelectedItems = this.selectedItems;
  },
  methods: {
    gasTypeIconSwitch(item) {
      const m = {
        1001: "keranqiti",
        2001: "a-dupinweixiangutoukulou",
        3001: "rongjieyang_mian"
      };
      return m[item.gasType] || "qitahuaxuewuzhi";
    },
    gasItemPopupChange(v) {
      const typeNames = [];
      const forceGasTypeList = this.forceGasTypeList || [];
      for (let i = 0; i < forceGasTypeList.length; i++) {
        const find = v.find(item => forceGasTypeList[i] === item.gasType);
        if (!find) {
          typeNames.push(this.forceGasTypeOptions[forceGasTypeList[i]]);
        }
      }
      if (typeNames.length) {
        this.submitValidate = false;
        return this.$toast.fail(
          `该作业必须进行${typeNames.join("、")}检测，请进行选择`
        );
      }
      this.submitValidate = true;
      const newData = v.map(i => {
        const f = this.selectedItems.find(f => i.id === f.id);
        return f ? f : i;
      });
      this.$emit("update:selectedItems", newData);
    },
    gasItemChoose() {
      this.popupVisible = true;
    },
    detectionValueInput(val) {
      const filterArr = this.selectedItems.filter(item => item.value2);
      const result = filterArr.every(item => {
        const min = parseFloat(item.rangeMin);
        const max = parseFloat(item.rangeMax);
        const curVal = parseFloat(item.value2);
        if (isNaN(curVal)) return false;
        return curVal >= min && curVal <= max;
      });
      this.$emit("update:result", result ? 1 : 0);
    },
    detection(value, rule) {
      if (!value) return true;
      const reg = /^-?(0|[1-9]\d{0,6})(\.\d{0,2})?$/;
      return reg.test(value);
    }
  },
  watch: {
    gasIds(newVal) {
      this.innerGasIds = newVal;
    },
    innerGasIds(newVal) {
      this.$emit("update:gasIds", newVal);
    },
    selectedItems(newVal) {
      this.innerSelectedItems = newVal;
    },
    innerSelectedItems(newVal) {
      this.$emit("update:selectedItems", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.gas-table-wrap {
  & ::v-deep {
    .van-cell {
      padding: 0;
    }
  }
}
.gas-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px 0 0;
  .btn {
    font-size: 12px;
    height: 24px;
    width: 48px;
  }
  .check-tips {
    font-size: 12px;
    color: #8c8f97;
    line-height: 17px;
    padding: 13px 16px 0;
  }
}
.table-check-gas {
  font-size: 14px;
  width: 100%;
  text-align: center;
  border-right: 1px solid #eceef2;
  border-bottom: 1px solid #eceef2;
  .gasType {
    font-size: 16px;
    color: #1576ff;
  }
  .gasType1001 {
    color: #f55f5f;
  }
  .gasType2001 {
    color: #f3961c;
  }
  .gasType3001 {
    color: #1ebaaf;
  }

  td {
    border-left: 1px solid #eceef2;
    border-top: 1px solid #eceef2;
    width: 33.3%;
    height: 50px;
    color: #2e2e4d;
    vertical-align: middle;
  }

  thead {
    td {
      background: #eceef2;
      color: #8c8f97;
      height: 30px;
      background: #eceef2;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[!_vm.editable ? 'gas-table-wrap' : '']},[(_vm.editable)?_c('div',{staticClass:"gas-row"},[_c('p',{staticClass:"check-tips"},[_vm._v("如现场不涉及某种检测项，检测值可为空。")]),_c('van-button',{staticClass:"btn-primary btn",attrs:{"size":"mini"},on:{"click":_vm.gasItemChoose}},[_vm._v("选择")])],1):_vm._e(),_c('van-field',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('table',{staticClass:"table-check-gas"},[_c('thead',[_c('tr',[_c('td',[_vm._v("检测项")]),_c('td',[_vm._v("合格范围")]),_c('td',[_vm._v("检测值")])])]),_c('tbody',_vm._l((_vm.innerSelectedItems),function(item,index){return _c('tr',{key:index},[_c('td',[_c('p',[_c('van-icon',{class:("gasType gasType" + (item.gasType)),attrs:{"class-prefix":"iconfont","name":_vm.gasTypeIconSwitch(item)}}),_vm._v(" "+_vm._s(item.name)+" ")],1)]),_c('td',[_vm._v(" "+_vm._s(((item.rangeMin) + "-" + (item.rangeMax) + " " + (item.unitName)))+" ")]),_c('td',[(_vm.editable)?_c('van-field',_vm._b({key:(index + "检测值"),attrs:{"type":"number","rules":[
                  {
                    validator: _vm.detection,
                    trigger: 'onBlur',
                    message: ' '
                  }
                ]},on:{"input":_vm.detectionValueInput},model:{value:(item.value2),callback:function ($$v) {_vm.$set(item, "value2", $$v)},expression:"item.value2"}},'van-field',_vm.fieldPropsInput,false)):(item.value)?_c('span',[_vm._v(_vm._s(((item.value) + " " + (item.unitName))))]):_vm._e()],1)])}),0)])]},proxy:true}])}),(_vm.editable)?_c('SelectPopupGrade',{ref:"selectPopupGrade",attrs:{"multi":true,"immediately":false,"list-key":"id","props":{
      label: 'name',
      value: 'id'
    },"output-type":"array","filter-key":"name","title":"气体检测设置","list":_vm.gasItemData,"visible":_vm.popupVisible,"popup-height":"70%","is-show-confrim-icon":false,"is-show-footer":"","submit-validate":_vm.submitValidate},on:{"update:visible":function($event){_vm.popupVisible=$event},"sure":_vm.gasItemPopupChange},scopedSlots:_vm._u([{key:"content",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+"（"+_vm._s(row.gasTypeName)+"） ")]}}],null,false,3410137176),model:{value:(_vm.innerGasIds),callback:function ($$v) {_vm.innerGasIds=$$v},expression:"innerGasIds"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
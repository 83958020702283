<template>
  <div>
    <van-field
      v-if="editable"
      input-align="right"
      error-message-align="right"
      readonly
      is-link
      :value="innerValue"
      :label="label"
      :name="label"
      :rules="rules"
      v-bind="$attrs"
      v-on="$listeners"
      @click="handleClick"
    />
    <van-cell v-else :title="label" :value="innerValue" />
    <van-popup v-model="popupVisible" position="bottom">
      <van-datetime-picker
        v-model="datetime"
        type="datetime"
        @confirm="handleConfirm"
        @cancel="popupVisible = false"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "FieldDateTimePicker",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "标题"
    },
    rules: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: "",
      popupVisible: false,
      datetime: new Date()
    };
  },
  mounted() {
    this.innerValue = this.value;
  },
  watch: {
    innerValue(newVal) {
      this.$emit("update:value", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  methods: {
    handleClick() {
      this.popupVisible = true;
    },
    handleConfirm(value) {
      this.innerValue = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      this.popupVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <van-cell :title="title" :border="false" />
    <FieldDateTimePicker
      label="取样时间"
      :value.sync="innerSampleTime"
      :rules="[
        {
          required: true,
          message: '请选择取样时间',
          trigger: 'onChange'
        }
      ]"
      :editable="editable"
    />
    <van-cell :class="[editable ? 'gas-table-wrap' : '']">
      <GasTable
        :gas-ids.sync="innerGasIds"
        :gas-item-data="gasItemData"
        :selected-items.sync="innerSelectedItems"
        :result.sync="innerResult"
        :editable="editable"
      />
    </van-cell>
    <FieldDateTimePicker
      label="检测时间"
      :value.sync="innerCheckTime"
      :rules="[
        {
          required: true,
          message: '请选择检测时间',
          trigger: 'onChange'
        }
      ]"
      :editable="editable"
    />
  </div>
</template>

<script>
import GasTable from "./GasTable";
import FieldDateTimePicker from "./FieldDateTimePicker";

export default {
  name: "GasTablePlus",
  components: {
    GasTable,
    FieldDateTimePicker
  },
  props: {
    title: {
      type: String,
      default: "标题"
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    gasIds: {
      type: Array,
      default: () => []
    },
    sampleTime: {
      type: String,
      default: ""
    },
    checkTime: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    gasItemData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      innerSampleTime: "",
      innerCheckTime: "",
      innerSelectedItems: [],
      innerResult: 1,
      innerGasIds: []
    };
  },
  mounted() {
    if (!this.editable) {
      this.innerSampleTime = this.sampleTime;
      this.innerCheckTime = this.checkTime;
      this.innerSelectedItems = this.selectedItems;
    }
  },
  methods: {},
  watch: {
    sampleTime(newVal) {
      this.innerSampleTime = newVal;
    },
    innerSampleTime(newVal) {
      this.$emit("update:sampleTime", newVal);
    },
    checkTime(newVal) {
      this.innerCheckTime = newVal;
    },
    innerCheckTime(newVal) {
      this.$emit("update:checkTime", newVal);
    },
    gasIds(newVal) {
      this.innerGasIds = newVal;
    },
    innerGasIds(newVal) {
      this.$emit("update:gasIds", newVal);
    },
    selectedItems(newVal) {
      this.innerSelectedItems = newVal;
    },
    innerSelectedItems(newVal) {
      this.$emit("update:selectedItems", newVal);
    },
    result(newVal) {
      this.innerResult = newVal;
    },
    innerResult(newVal) {
      this.$emit("update:result", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.gas-table-wrap {
  padding: 0;
}
</style>
